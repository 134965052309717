import { Params } from './basic-route-api';
import { TopLevelQueryParams, TopLevelRouteApi } from './top-level-route-api';

export interface UploadResult {
    success: boolean;
    message: string;
    path?: string;
}

export class TopLevelFileRouteApi<
    GetType,
    CreateType,
    QueryParamsType extends TopLevelQueryParams,
    DownloadParamsType,
> extends TopLevelRouteApi<GetType, CreateType, QueryParamsType> {
    get fileSubPath(): string {
        throw Error('The child class must implement this field');
    }

    async upload(id: number, data: Blob) {
        const formData: FormData = new FormData();
        formData.append('files', data);
        return (await this.api.client
            .post(this.basePath + '/' + id + '/' + this.fileSubPath, formData)
            .toPromise()) as UploadResult;
    }

    async download(id: number, params?: DownloadParamsType): Promise<Blob> {
        return (await this.api.client
            .get(this.basePath + '/' + id + '/' + this.fileSubPath, {
                responseType: 'blob',
                params: this.buildParams(params as unknown as Params),
            })
            .toPromise()) as Blob;
    }
}
