export type CommonColor =
    | 'base'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'accent'
    | 'pin'
    | 'success'
    | 'warn'
    | 'on-primary';

export const colorClass = (color?: CommonColor) => {
    switch (color ?? 'default') {
        case 'default':
            return 'text-gray-700 dark:text-gray-300';
        case 'primary':
            return 'text-primary-700 dark:text-primary-300';
        case 'secondary':
            return 'text-secondary';
        case 'accent':
            return 'text-accent';
        case 'pin':
            return 'text-[goldenrod]';
        case 'success':
            return 'text-green-700 dark:text-green-300';
        case 'warn':
            return 'text-red-500';
        case 'on-primary':
            return 'text-on-primary-500';
    }
};
export const bgColorClass = (color?: CommonColor) => {
    switch (color) {
        case 'base':
            return 'bg-orange-500 text-white';
        case 'primary':
            return 'bg-primary-500 text-white';
        case 'secondary':
            return 'bg-secondary text-white';
        case 'accent':
            return 'bg-accent text-white';
        case 'success':
            return 'bg-green-500 text-white';
        case 'warn':
            return 'bg-red-500 text-white';
        default:
            return 'bg-transparent border-2 border-[#0000001f] dark:border-[#ffffff1f]';
    }
};
