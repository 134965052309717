import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    Type,
    forwardRef,
    inject,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    NgControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { isDefined, isEmpty } from 'src/app/shared/common';

@Component({
    selector: 'sz-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent implements ControlValueAccessor, AfterViewInit {
    private cdr = inject(ChangeDetectorRef);
    @Input() icon: string;
    @Input() suffix: string | null = null;
    @Input() minimum?: number;
    @Input() small = false;
    get errorMessage(): string {
        const errors = this.control.errors;
        if (isEmpty(errors)) {
            return;
        }
        const generators = {
            min: ({ min }) =>
                this.translate.instant('common.error.minimum_value', { min }),
            max: ({ max }) =>
                this.translate.instant('common.error.maximum_value', { max }),
            maxlength: ({ requiredLength }) =>
                this.translate.instant('form.error.max_length', {
                    maxLength: requiredLength,
                }),
            required: () => this.translate.instant('error.required'),
            ...(this.errorMessages ?? {}),
        };
        for (const errorCode in generators) {
            const error = errors[errorCode];
            if (error) {
                return generators[errorCode](error);
            }
        }
        return 'UNKNOWN ERROR';
    }
    @Input() errorMessages?: Record<
        string,
        (error: Record<string, unknown>) => string
    >;
    @Input() type = 'text';

    control = new FormControl();

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    onChange = (value: string | number) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {};

    constructor(
        private translate: TranslateService,
        private injector: Injector,
    ) {}

    ngAfterViewInit() {
        // This strategy comes from https://stackoverflow.com/a/51126965
        const ngControl = this.injector.get<NgControl>(
            NgControl as Type<NgControl>,
        );
        if (ngControl) {
            this.control = ngControl.control as FormControl;
            // This makes sure our input is synced with the control value.
            this.cdr.detectChanges();
        }
    }

    writeValue(rawValue): void {
        let value;
        if (isDefined(rawValue)) {
            if (this.type == 'number' && ![null, ''].includes(rawValue)) {
                value = +rawValue;
            } else {
                value = rawValue;
            }
        }
        this.onChange(value);
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}
