<div
    class="flex grow items-center rounded-md border border-gray-400 bg-transparent text-base focus-within:!border-primary-500 dark:border-gray-500 dark:focus-within:!border-primary-400 has-[:disabled]:opacity-25"
>
    <textarea
        class="grow w-full resize-none rounded-md bg-transparent outline-none p-1.5 outline-0 focus:border-primary-500 leading-5"
        [required]="required"
        [rows]="rows"
        [formControl]="control"
        spellcheck="false"
        placeholder="{{ placeholder | translate }}"
    ></textarea>
    @if (control.invalid && (control.touched || control.dirty)) {
        <div class="aspect-square h-6 pr-1 text-red-500">
            <mat-icon
                svgIcon="exclamation-thick"
                [matTooltip]="errorMessage | translate"
                [inline]="true"
            />
        </div>
    }
</div>
