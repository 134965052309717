import {
    Component,
    DestroyRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { pause } from 'src/app/shared/common';
import { isMobile } from 'src/app/shared/screen-size-utils';

export type Section = {
    id: string;
    title: string;
    icon: string;
};

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sz-section-selector',
    templateUrl: './section-selector.component.html',
    styles: [':host { z-index: 100; }'],
})
export class SectionSelectorComponent implements OnInit {
    @Input() sections: Section[] = [];
    @Input() selectedId = '';
    @Input() useFragmentNavigation = true;

    @Output() selectedIdChange = new EventEmitter<string>();
    destroyRef = inject(DestroyRef);

    @HostListener('window:resize')
    onResize() {
        this.isMobile = isMobile();
    }

    get selectedIndex() {
        return this.findIndexById(this.selectedId) ?? 0;
    }

    public isMobile = false;
    public isMenuOpen = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    async ngOnInit() {
        this.isMobile = isMobile();
        this.route.fragment
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(async (frag) => {
                // wait for next micro task to avoid errors
                await pause();
                this.selectSection(frag ?? this.sections[0]?.id);
            });
    }

    /**
     * Handle a click on the section with the ID.
     */
    sectionClicked(id: string) {
        if (this.useFragmentNavigation) {
            const fragment = id;
            this.router.navigate(['.'], {
                relativeTo: this.route,
                fragment,
                replaceUrl: true,
            });
        } else {
            this.selectSection(id);
        }
    }

    /**
     * Select the previous section.
     */
    previousSection() {
        const index =
            this.selectedIndex == 0
                ? this.sections.length - 1
                : this.selectedIndex - 1;
        this.sectionClicked(this.sections[index].id);
    }

    /**
     * Select the next section.
     */
    nextSection() {
        const index =
            this.selectedIndex == this.sections.length - 1
                ? 0
                : this.selectedIndex + 1;
        this.sectionClicked(this.sections[index].id);
    }

    /**
     * Select the section with the given ID.
     */
    selectSection(sectionId: string) {
        if (this.findIndexById(sectionId) == null) {
            return;
        }
        this.selectedId = sectionId;
        this.selectedIdChange.emit(sectionId);
    }

    private findIndexById(sectionId: string) {
        const index = this.sections.findIndex(({ id }) => id == sectionId);
        return index == -1 ? null : index;
    }
}
