<div
    *ngIf="isMobile; else normal"
    class="flex w-full flex-row rounded-xl bg-base-200"
>
    <button
        mat-button
        class="!h-12 !rounded-none !rounded-l-xl"
        (click)="previousSection()"
    >
        <mat-icon svgIcon="chevron-left" class="!mr-0"></mat-icon>
    </button>
    <button
        class="relative w-full"
        (click)="isMenuOpen = !isMenuOpen"
        (mouseleave)="isMenuOpen = false"
    >
        @if (selectedId) {
            <div
                mat-button
                class="section selected text-on-primary-500 flex flex-row items-center justify-center gap-3 bg-primary-500 p-3 text-base"
            >
                <mat-icon [svgIcon]="sections[selectedIndex].icon"></mat-icon>
                <div>{{ sections[selectedIndex].title | translate }}</div>
            </div>
        }
        <div
            class="text-on-primary-500 isMenuOpen ? : {visible opacity-100} : absolute hidden w-full overflow-hidden bg-primary-500 transition-opacity"
        >
            <ng-container *ngFor="let section of sections; index as i">
                <button
                    *ngIf="i !== selectedIndex"
                    class="flex flex-row items-center justify-center gap-3 p-3 text-base"
                    (click)="sectionClicked(section.id)"
                >
                    <div class="flex items-center justify-center">
                        <mat-icon [svgIcon]="section.icon"></mat-icon>
                    </div>
                    <span>{{ section.title | translate }}</span>
                </button>
            </ng-container>
        </div>
    </button>
    <button
        mat-button
        class="!h-12 !rounded-none !rounded-r-xl"
        (click)="nextSection()"
    >
        <mat-icon svgIcon="chevron-right" class="!mr-0"></mat-icon>
    </button>
</div>
<ng-template #normal>
    <div class="flex w-52 flex-col overflow-clip rounded-xl bg-base-200">
        <button
            *ngFor="let section of sections"
            class="section flex cursor-pointer flex-row items-center justify-start gap-3 p-3 text-base"
            [class.selected]="section.id === selectedId"
            [class.bg-primary-500]="section.id === selectedId"
            [class.text-on-primary-500]="section.id === selectedId"
            (click)="sectionClicked(section.id)"
        >
            <mat-icon [svgIcon]="section.icon"></mat-icon>
            <div>{{ section.title | translate }}</div>
        </button>
    </div>
</ng-template>
