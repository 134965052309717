'use strict';

let list = {
  comma(string) {
    return list.split(string, [','], true);
  },
  space(string) {
    let spaces = [' ', '\n', '\t'];
    return list.split(string, spaces);
  },
  split(string, separators, last) {
    let array = [];
    let current = '';
    let split = false;
    let func = 0;
    let inQuote = false;
    let prevQuote = '';
    let escape = false;
    for (let letter of string) {
      if (escape) {
        escape = false;
      } else if (letter === '\\') {
        escape = true;
      } else if (inQuote) {
        if (letter === prevQuote) {
          inQuote = false;
        }
      } else if (letter === '"' || letter === "'") {
        inQuote = true;
        prevQuote = letter;
      } else if (letter === '(') {
        func += 1;
      } else if (letter === ')') {
        if (func > 0) func -= 1;
      } else if (func === 0) {
        if (separators.includes(letter)) split = true;
      }
      if (split) {
        if (current !== '') array.push(current.trim());
        current = '';
        split = false;
      } else {
        current += letter;
      }
    }
    if (last || current !== '') array.push(current.trim());
    return array;
  }
};
module.exports = list;
list.default = list;