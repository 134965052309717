<a
    class="flex items-center justify-center outline-none px-3"
    [ngClass]="[
        buttonSizeClasses,
        bgColorClasses,
        disabled ? 'opacity-25 pointer-events-none' : 'cursor-pointer'
    ]"
    [routerLink]="link"
    [queryParams]="queryParams"
>
    @if (icon) {
        <div [ngClass]="iconContainerClasses" class="mr-2">
            <div>
                <mat-icon [svgIcon]="icon" [inline]="true"></mat-icon>
            </div>
        </div>
    }
    <span>{{ text | translate }}</span>
</a>
