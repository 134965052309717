import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    forwardRef,
    inject,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    NgControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'sz-text-box',
    templateUrl: './text-box.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextBoxComponent),
            multi: true,
        },
    ],
})
export class TextBoxComponent implements ControlValueAccessor, AfterViewInit {
    private cdr = inject(ChangeDetectorRef);
    translate = inject(TranslateService);
    private _value = null;

    @Input() disabled = false;
    @Input() placeholder = '';
    @Input() required = false;
    @Input() rows = '2';
    @Input() errorMessages?: Record<
        string,
        (error: Record<string, unknown>) => string
    >;
    @Input() set value(v) {
        if (!this.disabled) {
            this.writeValue(v);
        }
    }

    get value() {
        return this._value;
    }

    get errorMessage(): string {
        const errors = this.control.errors;
        if (errors === null || errors === undefined) {
            return;
        }
        const generators = {
            required: () => this.translate.instant('error.required'),
            ...(this.errorMessages ?? {}),
            maxlength: ({ requiredLength }) =>
                this.translate.instant('form.error.max_length', {
                    maxLength: requiredLength,
                }),
        };
        for (const errorCode in generators) {
            const error = errors[errorCode];
            if (error) {
                return generators[errorCode](error);
            }
        }
        return 'UNKNOWN ERROR';
    }

    control = new FormControl();

    constructor(private injector: Injector) {}

    ngAfterViewInit(): void {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            this.control = ngControl.control as FormControl;
            // This makes sure our input is synced with the control value.
            this.cdr.detectChanges();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    onChange = (value) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {};

    writeValue(value) {
        this._value = value;
        this.onChange(this.value);
    }

    registerOnChange(fn: (value) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
}
