import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { bgColorClass, CommonColor } from 'src/app/shared/color';
import { isMobile } from 'src/app/shared/screen-size-utils';

export type ButtonSize = 'small' | 'medium' | 'large';
@Component({
    selector: 'sz-button',
    templateUrl: './button.component.html',
    styles: [':host { display: block }'],
})
export class ButtonComponent {
    @Input() icon: string;
    @Input({ required: true }) size: ButtonSize = 'small';
    @Input() bgColor: CommonColor = 'default';
    @Input() link: string;
    @Input() queryParams: Record<string, string>;
    @Input() disabled = false;
    @Input() text = 'btn.apply';

    isMobile = isMobile();

    private buttonSizeMap = {
        large: 'h-9 rounded-lg text-base',
        medium: 'h-7 rounded-md text-sm',
        small: 'h-6 rounded-md text-xs',
    };

    private iconSizeMap = {
        large: 'size-7',
        medium: 'size-5',
        small: 'size-4',
    };

    @HostBinding('style.pointer-events') get pointerEvents(): string {
        return this.disabled ? 'none' : 'auto';
    }

    @HostListener('window:resize')
    onResize() {
        this.isMobile = isMobile();
    }

    get adjustedSize() {
        return this.isMobile ? 'large' : this.size;
    }

    get bgColorClasses() {
        return bgColorClass(this.bgColor);
    }

    get buttonSizeClasses() {
        return (
            this.buttonSizeMap[this?.adjustedSize] ??
            this.buttonSizeMap['small']
        );
    }

    get iconContainerClasses() {
        return (
            this.iconSizeMap[this?.adjustedSize] ?? this.iconSizeMap['small']
        );
    }
}
